<template>
  <Header active_tab="profile"></Header>
  <div class="container-fluid">
    <h1>Feedbacks</h1>
    <div class="container">
      <div class="feedback" v-for="(feedback, index) in feedbacks" :key="index">
        <h3>{{ feedback.name }}</h3>
        <h4>{{ feedback.feedback }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import Header from "@/components/Header";
import { getFeedback } from "@/Utils/global";

export default {
  async mounted() {
    var x = await getFeedback("timestamp", "desc", 25);

    x = x.docs.map(doc => doc.data());
    this.feedbacks = x;
    for (var v in this.feedbacks) {
      this.feedbacks[v].name = this.feedbacks[v].name.toUpperCase();
    }
    store.state.loading = false;
  },
  components: {
    Header
  },
  data() {
    return {
      feedbacks: []
    };
  }
};
</script>
<style>
.container-fluid {
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(122, 0, 122, 0.349);
  width: 70%;
  padding: 10vmin;
  transform: translate(0%, 5%);
}
</style>
